// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=33t0u0koEr4f5AKkatOrUmmvEhz */

// Feel free to copy&paste color codes to your application */


// As hex codes */

$color-primary-0: #256689; // Main Primary color */
$color-primary-1: #6397B3;
$color-primary-2: #3D7797;
$color-primary-3: #145071;
$color-primary-4: #063C59;

$color-secondary-1-0: #D8AB32; // Main Secondary color (1) */
$color-secondary-1-1: #FFDE87;
$color-secondary-1-2: #EEC558;
$color-secondary-1-3: #B28817;
$color-secondary-1-4: #8C6602;

$color-secondary-2-0: #D85732; // Main Secondary color (2) */
$color-secondary-2-1: #FFA287;
$color-secondary-2-2: #EE7A58;
$color-secondary-2-3: #B23A17;
$color-secondary-2-4: #8C2102;


// As RGBa codes */

$rgba-primary-0: rgba(37, 102, 137, 1); // Main Primary color */
$rgba-primary-1: rgba(99, 151, 179, 1);
$rgba-primary-2: rgba(61, 119, 151, 1);
$rgba-primary-3: rgba(20, 80, 113, 1);
$rgba-primary-4: rgba(6, 60, 89, 1);

$rgba-secondary-1-0: rgba(216, 171, 50, 1); // Main Secondary color (1) */
$rgba-secondary-1-1: rgba(255, 222, 135, 1);
$rgba-secondary-1-2: rgba(238, 197, 88, 1);
$rgba-secondary-1-3: rgba(178, 136, 23, 1);
$rgba-secondary-1-4: rgba(140, 102, 2, 1);

$rgba-secondary-2-0: rgba(216, 87, 50, 1); // Main Secondary color (2) */
$rgba-secondary-2-1: rgba(255, 162, 135, 1);
$rgba-secondary-2-2: rgba(238, 122, 88, 1);
$rgba-secondary-2-3: rgba(178, 58, 23, 1);
$rgba-secondary-2-4: rgba(140, 33, 2, 1);



// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */


$app-body: $color-primary-4;
$section-header: white;
$section-menu: #ccc;
$section-nav: #ccc;
$section-chart: #032233;
$section-footer:#ccc;
$border-transparent: #ffffff3d;


$temp-quick-freeze: lighten($color-primary-1, 10%);
$temp-freeze: lighten($color-primary-3, 20%);
$temp-cold: lighten($color-primary-4, 10%);
$temp-warm: $color-secondary-1-3;
$temp-hot: $color-secondary-2-2;
$temp-very-hot: $color-secondary-2-4;

$footer-height: 50px;