@import './assets/scss/_variables.scss';

body {
  background-color: $app-body;
  color: white;

  .app-content-container {
    min-height: calc(100vh - #{$footer-height+20});
  }

}

