@import '../../assets/scss/variables';

.hour-temp {
    margin: 2px;
    padding: 2px;
    border: 1px solid #cccccc5e;
    font-size: 12px;
    flex-grow: 1;
    text-align: center;

    &.quick-freeze {
        background-color: $temp-quick-freeze;
        color: white;
    }

    &.freeze {
        background-color: $temp-freeze;
        color: white;
    }

    &.cold {
        background-color: $temp-cold;
        color: white;
    }

    &.warm {
        background-color: $temp-warm;
        color: white;
    }

    &.hot {
        background-color: $temp-hot;
        color: white;
    }

    &.very-hot {
        background-color: $temp-very-hot;
        color: white;
    }

    .temperature {
        // color:green;
    }

}


@media only screen and (max-width: 400px) {
    .hour-temp {
        .temperature {
            display: none;
        }
    }
  }