@import '../../assets/scss/variables';

.legend-weather {
    // background-color: red;
    padding: 10px;
    border-bottom: 1px solid $border-transparent;

    .title {
        font-weight: bold;
    }

    ul {
        list-style-type: none;
        display: flex;
        justify-content: space-around;

        &.disabled {
            display: none;
        }

        li {
            .key {
                margin-right: 10px;

                padding: 0px 5px 0px 5px;

                &.quick-freeze{
                    background-color: $temp-quick-freeze;
                    border-bottom:2px solid white;
                }
                &.freeze{
                    background-color: $temp-freeze;
                    border-bottom:2px solid white;
                }
                &.cold{
                    background-color: $temp-cold;
                    border: 1px solid $border-transparent;
                    border-bottom:2px solid white;
                }
                &.warm{
                    background-color: $temp-warm;
                    border-bottom:2px solid white;
                }
                &.hot{
                    background-color: $temp-hot;
                    border-bottom:2px solid white;
                }
                &.very-hot{
                    background-color: $temp-very-hot;
                    border-bottom:2px solid white;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .legend-weather {
        .title {
            padding-bottom: 12px;
        }
        ul {
            flex-direction: column;

            li {
                padding: 5px 5px 5px 5px;
            }
        }
    }
}
