@import './../../../assets/scss/_variables';

.story {
    // background-color: green;
    padding: 20px;
    // display: flex;
    // flex-direction: column;

    img {
        width: 400px;
        border: 1px solid white;
    }
}