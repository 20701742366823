@import '../../../assets/scss/variables';

.section-header {
    background-color: $section-header;
    color: $color-primary-3;
    padding: 10px;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    height: 50px;
    
}