@import '../../../assets/scss/variables';

.section-footer {
    height: $footer-height;
    padding: 10px;
    background-color: $section-footer;
    color: $color-primary-4;
    display: flex;
    align-items: center;
    font-size: 24px;
}