@import '../../assets/scss/variables';

$lighten: 30%;

.day-detail {
    border: 2px solid white;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;

    &.freeze-day {
        background-color: $temp-quick-freeze;
    }

    &.quick-freeze {
        border-color: lighten($temp-quick-freeze, $lighten);
    }

    &.freeze {
        border-color: lighten($temp-freeze, $lighten);
    }

    &.cold {
        border-color: lighten($temp-cold, $lighten);
    }

    &.warm {
        border-color: $temp-warm;
    }

    &.hot {
        border-color: $temp-hot;
    }

    &.very-hot {
        border-color: $temp-very-hot;
    }

    .daily-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .hourly-content {
        display: flex;
        justify-content: space-between;
    }

}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    /* Styles */
    .day-detail {
        font-size: 16px;

        .weather-summary {
            display: none;
        }
    }

}