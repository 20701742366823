.main {
    min-height: 200px;
    padding: 20px;

    a {
        color: white;
    }

    .select-year {
        margin: 10px;
        min-width: 200px;
        
        label {
            color: white;
            margin-left: 10px;
        }
    }

    .select-winter-year {
        margin: 10px;
        min-width: 200px;

        label {
            color: white;
            margin-left: 10px;
        }
    }

    .skate-hour {
        padding: 4px;
        border: 1px solid;
        width: 40px;
        display: inline-block;
        text-align: center;

        &.freeze {
            background-color: black;
        }

        &.cold {
            background-color: black;
        }
    }

    .no-skate-hour {
        padding: 4px;
        width: 40px;
        display: inline-block;
        text-align: center;
    }

    .quick-freeze {
        color: black;
        border-color: black;
        background-color: white;
    }

    .freeze {
        color: #58a9ff;
        border-color: #58a9ff;
        background-color: #cccccc;
    }

    .cold {
        color: white;
        border-color: white;
    }

    .warm {
        color: yellow;
        border-color: yellow;
    }

    .hot {
        color: #ce4e01;
        border-color: orange;
    }

    .very-hot {
        color: white;
        border-color: orange;
        background-color: red;
    }

}