@import './../../../assets/scss/_variables';

.ui-design {
    // background-color: green;
    padding: 20px;
    display: flex;
    flex-direction: column;

    img {
        border: 1px solid white;
    }
}