@import '../../../assets/scss/variables';

.section-menu {
    background-color: $section-menu;
    display: flex;
    padding: 10px;
    justify-content: space-around;
    color: $color-primary-4;


    .select-year {
        min-width: 200px;
        
        label {
            color: $color-primary-4;
            margin-left: 10px;
        }
    }

    .select-winter-year {
        min-width: 200px;

        label {
            color: $color-primary-4;
            margin-left: 10px;
        }
    }

    .year-selection-type {
        padding-top: 10px;
        margin-right: 10px;
    }
}