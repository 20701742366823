@import '../../../assets/scss/variables';

.section-nav {
    color: white;
    border-bottom: 1px solid $border-transparent;
    padding-left: 20px;
    padding-right: 20px;

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        list-style: none;
        padding-left: 0;

        li {
            a {
                color: white;
                text-decoration: none;
                font-weight: bold;

                &:link {
                    // color:red;
                }

                &:visited {
                    // color: green;
                }

                &:hover {
                    color: red;
                }
            }
        }
    }
}